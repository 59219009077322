<template>
  <AdminPage>
    <template slot="header">
      <v-row>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-if="false" v-model="dialog" max-width="80vw">
            <template v-slot:activator="{on, attrs}">
              <v-btn color="primary white--text"
                     v-bind="attrs"
                     v-on="on">
                {{$t('forms.client.new')}}
              </v-btn>
            </template>
            Neuer Kunde
          </v-dialog>
        </v-toolbar>
      </v-row>
    </template>
    <v-flex>
  <v-row>
    <v-col :cols="currentClient ? 8 : 12">
  <AdminCard v-if="localClient" no-border>
    <template slot="title">
      <v-flex>
        <v-row>
          <v-text-field
              v-model="clientName"
              append-icon="mdi-magnify"
              label="Suche..."
              class="px-4"
              single-line
              hide-details
          ></v-text-field>
        </v-row>
      </v-flex>
    </template>
    <v-data-table :items="admin_clients" :headers="headers" :search="clientName"
                  :loading="$apollo.queries.admin_clients.loading" @click:row="showDetails">
      <template v-slot:item.active="{ item }">
        <v-simple-checkbox
            :value="item.active"
            disabled
        ></v-simple-checkbox>
      </template>
    </v-data-table>
  </AdminCard>
    </v-col>
    <v-col cols="4" v-if="currentClient">
      <AdminCard>
        <template slot="title">Detailansicht</template>
        <ClientId :client="currentClient"></ClientId>
        <template slot="actions"><RoleContent role="admin.clients.password_reset"><NewPassword :id="currentClient._id"></NewPassword></RoleContent></template>
      </AdminCard>
    </v-col>
  </v-row>
    </v-flex>
  </AdminPage>
</template>
<script>
import AdminCard from "@/components/generic/AdminCard";
import {ADMIN_CLIENTS} from '@/graphql'
import AdminPage from "@/components/generic/AdminPage";
import ClientId from "@/components/admin/clients/ClientId";
import NewPassword from '@/components/generic/NewPassword';
import RoleContent from '@/components/generic/RoleContent';
export default {
  components: {RoleContent, NewPassword, ClientId, AdminPage, AdminCard},
  props: ['localClient', 'nodeId'],
  data () {
    return {
      clientName: '',
      currentClient: null,
      dialog: false,
      headers: [
        {
          text: this.$t("content.clients.table.first_name"),
          value: 'firstName'
        },
        {
          text: this.$t("content.clients.table.last_name"),
          value: 'lastName'
        },
        {
          text: this.$t("content.clients.table.email"),
          value: 'email'
        },
        {
          text: this.$t("content.clients.table.type"),
          value: 'client_type'
        },
        {
          text: this.$t("content.clients.table.active"),
          value: 'active'
        }
      ]
    }
  },
  apollo: {
    admin_clients: {
      query: ADMIN_CLIENTS,
      variables () {
        return {
          node: this.nodeId
        }
      }
    }
  },
  methods: {
    showDetails (client) {
      this.currentClient = client
    }
  }
}
</script>