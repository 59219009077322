export const companyNotificationTemplates = [
  {
    value: null,
    text: '-',
  },
  {
    value: {
      title: 'Update verfügbar',
      content:
        '<p>Vielen Dank, dass Sie sanadeus verwenden. Dieses Release enthält Fehlerkorrekturen, durch die unser' +
        ' Produkt verbessert wird. Bitte laden Sie dieses Fenster neu, damit die Änderungen verfügbar sind.</p>',
      showReloadAction: true,
    },
    text: 'Regelmäßiges Deployment',
  },
]
