import Vue from 'vue'
import DatetimePicker from 'vuetify-datetime-picker'
import App from './App.vue'
import './filters'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import { APOLLO } from './vue-apollo'

Vue.config.productionTip = false

Vue.use(DatetimePicker)

new Vue({
  router,
  vuetify,
  i18n,
  apolloProvider: APOLLO,
  render: (h) => h(App),
}).$mount('#app')
