<template>
  <v-list
      dense
      nav
  >
    <RoleContent v-for="(nav, i) in navEntries"
                 :key="i"
                 :role="nav.role">
      <v-list-item :to="nav.link"
                   link>
        <v-list-item-icon>
          <v-icon>{{ nav.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ nav.name }}</v-list-item-title>
      </v-list-item>
    </RoleContent>
  </v-list>
</template>

<script>
import RoleContent from '@/components/generic/RoleContent'

export default {
  name: 'Navigation',
  components: { RoleContent },
  data() {
    return {
      navEntries: [
        {
          name: this.$t('navigation.dashboard'),
          link: { name: 'admin.dashboard' },
          icon: 'mdi-monitor-dashboard',
        },
        {
          name: this.$t('navigation.nodes'),
          link: { name: 'admin.nodes' },
          icon: 'mdi-hospital-box',
          role: 'admin.nodes',
        },
        {
          name: this.$t('navigation.prospects'),
          link: { name: 'admin.prospects' },
          icon: 'mdi-account-question',
          role: 'admin.prospects',
        },
        {
          name: this.$t('navigation.errors'),
          link: { name: 'admin.errors' },
          icon: 'mdi-message-alert',
          role: 'admin.errors',
        },
        {
          name: this.$t('navigation.company_notifications'),
          link: { name: 'admin.company_notifications' },
          icon: 'mdi-bullhorn',
          role: 'admin.company_notifications',
        },
        {
          name: this.$t('navigation.jobs'),
          link: { name: 'admin.bull' },
          icon: 'mdi-cog',
          role: 'admin.bull',
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
