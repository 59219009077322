<template>
  <PageNavigation :tabs="allTabs"></PageNavigation>
</template>
<script>
import PageNavigation from "@/components/generic/PageNavigation"
import {NODE_TABS} from '@/graphql'

export default {
  name: "NodeNavigation",
  components: {PageNavigation},
  apollo: {
    nodeTabs: {
      query: NODE_TABS
    }
  },
  computed: {
    allTabs () {
      if (!this.nodeTabs) {
        return this.tabs
      } else {
        return [...this.tabs, ...this.nodeTabs]
      }
    }
  },
  data() {
    return {
      tabs: [
        {
          name: this.$t('content.nodes.tabs.nodes'),
          link: {name: 'admin.nodes.list'},
          role: 'admin.nodes.list'
        },
        {
          name: this.$t('content.nodes.tabs.nodes_tree'),
          link: {name: 'admin.nodes.tree'},
          role: 'admin.nodes.list'
        },
      ]
    }
  }

}
</script>
