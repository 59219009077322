<template>
  <ErrorList :no-border="true" :errors="admin_node_errorlogs" :loading="$apollo.queries.admin_node_errorlogs.loading"></ErrorList>
</template>
<script>
import {ADMIN_NODE_ERRORLOGS} from '@/graphql'
import ErrorList from "@/components/errors/ErrorList";
export default {
  components: {ErrorList},
  apollo: {
    admin_node_errorlogs: {
      query: ADMIN_NODE_ERRORLOGS,
      variables () {
        return {
          node: this.$route.params.node_id
        }
      }
    }
  }
}
</script>