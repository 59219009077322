<template>
 <JsonContent :json-item="client"></JsonContent>
</template>
<script>
  import JsonContent from "@/components/generic/JsonContent";
  export default {
    name: 'ClientId',
    components: {JsonContent},
    props: ['client']
  }
</script>