<template>
    <AdminPage>
        <template slot="header">{{$t('content.nodes.title')}}</template>
        <template slot="navigation"><NodeNavigation/></template>
        <router-view></router-view>
    </AdminPage>
</template>
<script>
  import NodeNavigation from "../../../components/admin/nodes/NodeNavigation";
  import AdminPage from "../../../components/generic/AdminPage";
  export default {
    components: {AdminPage, NodeNavigation}
  }
</script>
