<template>
    <div>
        <div class="headline"><slot name="header"></slot></div>
        <v-divider v-if="$slots.header"></v-divider>
        <slot name="navigation" class="pa-2"></slot>
        <slot></slot>
    </div>
</template>
<script>
    export default {
      name: "AdminPage"
    }
</script>
