<template>
  <div class="json-wrapper">
    <v-dialog v-model="details" max-width="600">
      <v-card tile>
        <v-card-text>
          <JsonKey :item="content"></JsonKey>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div  v-for="(key, i) in iterableFields" :key="i" class="valueRow">
      <div v-if="!key.expand" class="name">{{ key.name }}:
        <JsonKey class="pl-1 keyValue" :item="jsonItem[key.name]"></JsonKey>
      </div>
      <div v-if="key.expand" class="name" @click="setDetail(key)">{{ key.name }}<span class="pl-1" v-if="jsonItem[key.name].length">({{ jsonItem[key.name].length }})</span></div>
    </div>
  </div>
</template>
<style>
.json-wrapper {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
}

.valueRow {
  display: flex;
  flex-direction: row;
  flex: 0 0 100%;
}

.name {
  display: flex;
  color: blue;
  padding-right: 10px;
  flex: 0 1 auto;
}

.keyValue {
  display: flex;
  flex: 1 1 auto;
  color: grey;
}


</style>
<script>
import JsonKey from "@/components/generic/JsonKey";

export default {
  name: 'JsonContent',
  components: {JsonKey},
  props: ['jsonItem'],
  data() {
    return {
      details: false,
      content: {}
    }
  },
  computed: {
    iterableFields() {
      try {
        const regularFields = Object.keys(this.jsonItem).sort().filter((i) => {
          return !i.includes('__') // Felder beginnend mit __ halten die Feldhistorie
        }).map((f) => {
          return {name: f, expand: false}
        })
        const historyFields = Object.keys(this.jsonItem).sort().filter((i) => {
          return i.includes('__') && i !== '__v' // Felder beginnend mit __ halten die Feldhistorie
        }).map((f) => {
          return {name: f, expand: true, show: false}
        })
        return [ ...regularFields, ...historyFields]
      } catch (err) {
        // query hat noch nicht fertig geladen
        return []
      }
    }
  },
  methods: {
    setDetail (key) {
      this.content = this.jsonItem[key.name]
      this.details = true
    }
  }
}
</script>
