<template>
  <AdminPage>
    <template slot="header">{{$t('content.errors.title')}}</template>
    <ErrorList :errors="admin_errorlogs" :loading="$apollo.queries.admin_errorlogs.loading"></ErrorList>
  </AdminPage>
</template>
<script>
import {ADMIN_ERRORLOGS} from '@/graphql'
import ErrorList from "@/components/errors/ErrorList";
import AdminPage from "@/components/generic/AdminPage";
export default {
  components: {AdminPage, ErrorList},
  apollo: {
    admin_errorlogs: {
      query: ADMIN_ERRORLOGS
    }
  }
}
</script>