<template>
  <div>
    <v-data-table :headers="headers"
                  :items="companyNotifications"
                  :loading="!companyNotifications"
                  item-class="cursor-pointer"
                  item-key="_id"
                  @click:row="editCompanyNotification">
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer />
          <v-dialog v-model="createDialog"
                    max-width="700px">
            <template #activator="{ on, attrs }">
              <v-btn color="primary"
                     v-bind="attrs"
                     v-on="on">
                {{ $t('content.company_notifications.actions.create') }}
              </v-btn>
            </template>
            <AddCompanyNotification @companyNotificationCreated="companyNotificationCreated()" />
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.createdAt="{ item }">
        {{ dateFormat(item.createdAt) }}
      </template>
      <template #item.updatedAt="{ item }">
        {{ dateFormat(item.updatedAt) }}
      </template>
      <template #item.published="{ item }">
        <v-icon v-if="item.published"
                color="green">mdi-check
        </v-icon>
        <v-icon v-else
                color="red">mdi-close
        </v-icon>
      </template>
      <template #item.publishAt="{ item }">
        <template v-if="!item.publishAt">
          <v-chip class="text-uppercase font-weight-bold"
                  small>
            manuell
          </v-chip>
        </template>
        <template v-else>
          {{ dateFormat(item.publishAt) }}
        </template>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn icon
                     @click.stop="editCompanyNotification(item)">
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </span>
          </template>
          Bearbeiten
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn icon
                     @click.stop="deleteCompanyNotification(item._id)">
                <v-icon small>
                  mdi-delete
                </v-icon>
              </v-btn>
            </span>
          </template>
          Löschen
        </v-tooltip>
        <v-tooltip v-if="!item.published"
                   top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn
                  icon
                  @click.stop="publishCompanyNotification(item._id)">
                <v-icon small>
                  mdi-air-horn
                </v-icon>
              </v-btn>
            </span>
          </template>
          Veröffentlichen
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="editDialog"
              max-width="700px">
      <EditCompanyNotification :companyNotification="editedItem"
                               @companyNotificationEdited="companyNotificationEdited()" />
    </v-dialog>
  </div>
</template>

<script>
import AddCompanyNotification from '@/components/admin/company_notifications/AddCompanyNotification.vue'
import EditCompanyNotification from '@/components/admin/company_notifications/EditCompanyNotification.vue'

export default {
  name: 'CompanyNotificationList',
  components: {
    EditCompanyNotification,
    AddCompanyNotification,
  },
  props: ['companyNotifications'],
  methods: {
    dateFormat(value) {
      return new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(value))
    },
    editCompanyNotification(item) {
      this.editDialog = true
      this.editedItem = item
    },
    async publishCompanyNotification(companyNotificationId) {
      try {
        await this.$apolloProvider.defaultClient.mutate({
          mutation: require('@/graphql/mutations/admin_publish_company_notification.mutation.graphql'),
          variables: {
            companyNotificationId,
          },
        })
        this.$emit('companyNotificationPublished')
      } catch (error) {
        throw new Error(error)
      }
    },
    async deleteCompanyNotification(companyNotificationId) {
      try {
        await this.$apolloProvider.defaultClient.mutate({
          mutation: require('@/graphql/mutations/admin_delete_company_notification.mutation.graphql'),
          variables: {
            companyNotificationId,
          },
        })
        this.$emit('companyNotificationDeleted')
      } catch (error) {
        throw new Error(error)
      }
    },
    companyNotificationCreated() {
      this.createDialog = false
      this.$emit('companyNotificationCreated')
    },
    companyNotificationEdited() {
      this.editDialog = false
      this.$emit('companyNotificationEdited')
    },
  },
  data() {
    return {
      createDialog: false,
      editDialog: false,
      deleteDialog: false,
      publishDialog: false,
      editedItem: null,
      headers: [
        {
          text: this.$t('content.company_notifications.table.title'),
          value: 'title',
        },
        {
          text: this.$t('content.company_notifications.table.createdAt'),
          value: 'createdAt',
          width: '1px',
          cellClass: 'text-no-wrap',
        },
        {
          text: this.$t('content.company_notifications.table.updatedAt'),
          value: 'updatedAt',
          width: '180px',
          cellClass: 'text-no-wrap',
        },
        {
          text: this.$t('content.company_notifications.table.publishAt'),
          value: 'publishAt',
          width: '200px',
          cellClass: 'text-no-wrap',
        },
        {
          text: this.$t('content.company_notifications.table.published'),
          value: 'published',
          width: '1px',
          align: 'right',
          sortable: false,
        },
        {
          cellClass: 'text-no-wrap',
          text: null,
          value: 'actions',
          width: '1px',
          sortable: false,
        },
      ],
    }
  },
}
</script>

<style>
table tr {
  cursor: pointer;
}
</style>
