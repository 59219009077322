<template>
  <ClientList :local-client="localClient" :node-id="$route.params.node_id"></ClientList>
</template>
<script>
  import {LOCAL_CLIENT} from "../../../graphql/local";
  import ClientList from "@/components/admin/clients/ClientList";

  export default {
    components: {ClientList},
    apollo: {
      localClient: {
        query: LOCAL_CLIENT
      }
    }
  }
</script>
