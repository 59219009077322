<template>
  <AdminCard>
    <v-tabs vertical>
      <v-tab :disabled="tab.disabled" v-for="(tab,i) in $options.filters.roleEntries(tabs)" :to="tab.link" :key="i">{{tab.name}}</v-tab>
      <v-tabs-items>
        <router-view></router-view>
      </v-tabs-items>
    </v-tabs>
  </AdminCard>
</template>
<script>
import AdminCard from "@/components/generic/AdminCard";
import {ADMIN_NODE} from "../../../graphql/queries";
import {ADD_NODE_TAB} from "../../../graphql/local";

export default {
  components: {AdminCard},
  apollo: {
    admin_node: {
      query: ADMIN_NODE,
      variables() {
        return {
          id: this.$route.params.node_id
        }
      },
      result ({data}) {
        this.addTab(data.admin_node, {name: this.$route.name, params: this.$route.params})
      }
    }
  },
  watch: {
    "$route": function (newRoute, oldRoute) {
      if (!newRoute.params || !newRoute.params.close) {
        this.addTab(this.admin_node, {name: oldRoute.name, params: oldRoute.params})
      }
    }
  },
  methods: {
    addTab (node, link) {
      this.$apollo.mutate({
        mutation: ADD_NODE_TAB,
        variables: {
          tab: {
            _id: node._id,
            name: node.name,
            link,
            closable: true
          }
        }
      })
    }
  },
  data () {
    return {
      tabs: [
        {
          name: this.$t('content.nodes.details.base'),
          link: {name: 'admin.nodes.list.node_id.base'},
          role: 'admin.nodes.list'
        },
        {
          name: this.$t('content.nodes.details.clients'),
          link: {name: 'admin.nodes.list.node_id.clients'},
          role: 'admin.nodes.list.clients'
        },
        {
          name: this.$t('content.nodes.details.errors'),
          link: {name: 'admin.nodes.list.node_id.errors'},
          role: 'admin.nodes.list.errors'
        },
        {
          name: this.$t('content.nodes.details.raw'),
          link: {name: 'admin.nodes.list.node_id.raw'},
          role: 'admin.nodes.list.raw'
        },
        {
          name: this.$t('content.nodes.details.layout'),
          disabled: true
        },
        {
          name: this.$t('content.nodes.details.reminders'),
          disabled: true
        },
        {
          name: this.$t('content.nodes.details.files'),
          disabled: true
        },
        {
          name: this.$t('content.nodes.details.invoices'),
          disabled: true
        },
        {
          name: this.$t('content.nodes.details.epost'),
          disabled: true
        },
        {
          name: this.$t('content.nodes.details.finapi'),
          disabled: true
        }
      ]
    }
  }
}
</script>
