<template>
  <AdminPage>
    <template slot="header">{{$t('content.prospects.title')}}</template>
    <template slot="navigation">
      <v-row v-if="false">
        <v-radio-group
            v-model="state"
            row
            class="px-4"
        >
          <v-radio
              :label="$t('content.prospects.states.mail_sent')"
              value="mail_sent"
          ></v-radio>
          <v-radio
              :label="$t('content.prospects.states.mail_failed')"
              value="mail_failed"
          ></v-radio>
          <v-radio
              :label="$t('content.prospects.states.mail_confirmed')"
              value="mail_confirmed"
          ></v-radio>
        </v-radio-group>
      </v-row>
    </template>
    <ProspectList :prospects="admin_prospects">
    </ProspectList>
  </AdminPage>
</template>
<script>
import {ADMIN_PROSPECTS} from '@/graphql'
import ProspectList from '@/components/admin/prospects/ProspectList';
import AdminPage from '@/components/generic/AdminPage';

export default {
  components: {AdminPage, ProspectList},
  apollo: {
    admin_prospects: {
      query: ADMIN_PROSPECTS,
      variables () {
        return {
          sortBy: "-createdAt"
        }
      }
    }
  },
  data () {
    return {
      state: 'mail_sent'
    }
  }
}
</script>