<template>
  <AdminPage>
    <template #header>{{ $t('content.company_notifications.title') }}</template>
    <CompanyNotificationList :company-notifications="admin_company_notifications"
                             @companyNotificationCreated="$apollo.queries.admin_company_notifications.refetch()"
                             @companyNotificationDeleted="$apollo.queries.admin_company_notifications.refetch()"
                             @companyNotificationEdited="$apollo.queries.admin_company_notifications.refetch()"
                             @companyNotificationPublished="$apollo.queries.admin_company_notifications.refetch()" />
  </AdminPage>
</template>

<script>
import CompanyNotificationList from '@/components/admin/company_notifications/CompanyNotificationList'
import AdminPage from '@/components/generic/AdminPage'

export default {
  name: 'CompanyNotifications',
  components: {
    CompanyNotificationList,
    AdminPage,
  },
  apollo: {
    admin_company_notifications: {
      query: require('@/graphql/queries/admin_company_notifications.query.graphql'),
    },
  },
}
</script>
