<template>
  <div class="nav-tabs">
      <div v-ripple class="nav-tab" :class="{active: isActiveTab(tab)}" v-for="(tab, i) in $options.filters.roleEntries(tabs)" :key="i" exact>
        <div class="link" @click="openTab(tab)">{{tab.name}}</div>
        <div v-if="tab.closable" class="close-btn"><v-btn @click="close(tab)" icon small rounded><v-icon>mdi-close</v-icon></v-btn></div>
      </div>
  </div>
</template>
<style scoped>
  .nav-tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 48px;
    max-height: 48px;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
  }
  .nav-tab {
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    border-left: 1px solid rgba(0, 0, 0, .2);
    border-top:1px solid rgba(0, 0, 0, .2);
    align-items: center;
    margin-bottom: -12px;
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-topright: 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    position: relative;
  }

  .link {
    padding:5px 10px;
  }

  .close-btn {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    padding: 0px 5px;
  }

  .close-btn .v-btn {
    height: 24px;
    width: 24px;
  }

  .nav-tab:last-child {
    border-right: 1px solid rgba(0, 0, 0, .2);
  }

  .nav-tab.active:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 2px solid #4caf50;
  }

</style>
<script>
import {CLOSE_TAB, LOCAL_CLIENT} from '@/graphql'
import equal from 'deep-equal'

export default {
  name: 'PageNavigation',
  props: ['tabs'],
  apollo: {
    localClient: {
      query: LOCAL_CLIENT
    }
  },
  methods: {
    close (tab) {
      let vm = this
      this.$apollo.mutate({
        mutation: CLOSE_TAB,
        variables: {
          tab: tab,
          tabType: 'node'
        },
        update () {
          if (vm.isActiveTab(tab)) {
            let nextLink = vm.tabs[vm.tabs.length-1].link
            if (!nextLink.params) nextLink.params = {}
            nextLink.params['close'] = true // otherwise tab stays open due to setting the old link on route change
            vm.$router.push(nextLink)
          }
        }
      })
    },
    isActiveTab (tab) {
      let tabRoute = this.$router.resolve(tab.link)
      return this.$router.resolve(tab.link).href == this.$route.fullPath || (equal(tabRoute.route.params, this.$route.params) && (this.$route.meta.nav && tabRoute.route.meta.nav == this.$route.meta.nav))
    },
    openTab (tab) {
      if (!this.isActiveTab(tab)) {
        this.$router.push(tab.link)
      }
    }
  }
}
</script>

