<template>
  <div>
    <div :class="typeof item" v-if="['string', 'number'].includes(typeof(item))">
      {{ item }}
    </div>
    <div :class="typeof item" v-if="typeof item === 'boolean'">
      <v-icon color="green" v-if="item">mdi-check</v-icon>
      <v-icon color="red" v-if="!item">mdi-close</v-icon>
    </div>
    <div v-if="Array.isArray(item) && item.length">
      <JsonContent v-for="(item, i) in item" :key="i" :json-item="item"></JsonContent>
    </div>
    <div v-if="item && typeof item === 'object' && !Array.isArray(item)">
      <JsonContent :json-item="item"></JsonContent>
    </div>
    <div class="no-data" v-if="(!item && typeof item === 'object')">
      &lt;leer&gt;
    </div>
    <div class="no-data" v-if="Array.isArray(item) && !item.length">
      [ ]
    </div>
  </div>
</template>
<style scoped>
.boolean {
  color: green;
}

.number {
  color: purple;
}

.string {
  color: darkgreen;
}

.no-data {
  color: grey;
}
</style>
<script>
export default {
  name: 'JsonKey',
  components: {
    JsonContent: () => import('./JsonContent')
  },
  props: ['item']
}
</script>