<template>
  <v-card>
    <v-card-title>
      {{ $t('content.company_notifications.dialog.edit.title') }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-select v-model="template.selected"
                    :items="template.items"
                    dense
                    label="Vorlage"
                    outlined
                    return-object />
        </v-col>
      </v-row>
      <v-form ref="formRef"
              v-model="valid">
        <v-row>
          <v-col>
            <v-text-field v-model="companyNotification.title"
                          :label="$t('content.company_notifications.form.labels.title')"
                          :rules="[rules.required]"
                          dense
                          outlined />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label>
              {{ $t('content.company_notifications.form.labels.content') }}
            </label>
            <vue-ckeditor v-model="companyNotification.content"
                          :config="editorConfig" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-datetime-picker v-model="publishAtModel"
                               :date-picker-props="{
                                 outlined: true,
                                 dense: true,
                                 color: 'primary'
                               }"
                               :label="$t('content.company_notifications.form.labels.publishAt')"
                               :text-field-props="{
                                 outlined: true,
                                 dense: true,
                                 color: 'primary'
                               }"
                               :time-picker-props="{
                                 format: '24hr',
                                 outlined: true,
                                 dense: true,
                                 color: 'primary'
                               }"
                               clearText="Abbrechen"
                               okText="Übernehmen">
              <template #dateIcon>
                <v-icon>mdi-calendar-blank</v-icon>
              </template>
              <template #timeIcon>
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox v-model="companyNotification.showReloadAction"
                        :label="$t('content.company_notifications.form.labels.showReloadAction')"
                        dense
                        outlined />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn :disabled="!valid"
             color="primary"
             @click="save">
        {{ $t('content.company_notifications.actions.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { companyNotificationTemplates } from '@/components/admin/company_notifications/companyNotificationTemplates'
import { RULES } from '@/rules'
import VueCkeditor from 'vue-ckeditor2'

export default {
  name: 'EditCompanyNotification',
  components: { VueCkeditor },
  computed: {
    rules() {
      return RULES
    },
    publishAtModel: {
      get() {
        return new Date(this.companyNotification.publishAt)
      },
      set(value) {
        this.companyNotification.publishAt = value
      },
    },
  },
  watch: {
    'template.selected': {
      handler(value) {
        if (value.value) {
          Object.assign(this.companyNotification, value.value)
          return
        }
        this.companyNotification.title = ''
        this.companyNotification.content = ''
        this.companyNotification.showReloadAction = false
      },
      deep: true,
    },
  },
  props: {
    companyNotification: {
      type: Object,
      required: true,
      default: () => {
        return {
          _id: null,
          title: null,
          content: null,
        }
      },
    },
  },
  data() {
    return {
      template: {
        selected: null,
        items: companyNotificationTemplates,
      },
      editorConfig: {
        toolbar: [
          [
            'Format',
            '-',
            'Bold',
            'Italic',
            'Underline',
            '-',
            'NumberedList',
            'BulletedList',
          ],
        ],
        height: 200,
      },
      valid: false,
    }
  },
  methods: {
    async save() {
      try {
        const {
                _id,
                title,
                content,
                showReloadAction,
                publishAt,
              } = this.companyNotification
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/admin_edit_company_notification.mutation.graphql'),
          variables: {
            companyNotification: {
              _id,
              title,
              content,
              showReloadAction,
              publishAt,
            },
          },
        })
        this.$emit('companyNotificationEdited')
      } catch (error) {
        throw new Error(error)
      }
    },
  },
}
</script>

<style>
.cke {
  border-color: rgba(0, 0, 0, 0.42) !important;
  border-radius: 5px;
}

.cke_top,
.cke_inner,
.cke_bottom {
  background: transparent !important;
}
</style>
