<template>
  <AdminCard no-border>
    <template slot="title">
      <v-flex>
        <v-row>
          <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              :label="$t('generic.search_hint')"
              class="px-4"
              single-line
              hide-details
          ></v-text-field>
        </v-row>
        <v-row>
          <v-radio-group
              v-model="filterState"
              row
              class="px-4"
          >
            <v-radio
                :label="$t('content.prospects.states.all')"
                value="all"
            ></v-radio>
            <v-radio
                :label="$t('content.prospects.states.mail_sent')"
                value="mail_sent"
            ></v-radio>
            <v-radio
                :label="$t('content.prospects.states.mail_failed')"
                value="mail_failed"
            ></v-radio>
            <v-radio
                :label="$t('content.prospects.states.mail_confirmed')"
                value="mail_confirmed"
            ></v-radio>
            <v-radio
                :label="$t('content.prospects.states.password_set')"
                value="password_set"
            ></v-radio>
            <v-radio
                :label="$t('content.prospects.states.contact_set')"
                value="contact_set"
            ></v-radio>
            <v-radio
                :label="$t('content.prospects.states.client_created')"
                value="client_created"
            ></v-radio>
          </v-radio-group>
        </v-row>
      </v-flex>
    </template>
    <v-data-table :loading="!prospects" :items="prospectsByState" :headers="headers" item-key="_id" :search="searchText" show-expand :options.sync="options" :footer-props="{itemsPerPageOptions: [10, 20, 50, 100, -1]}">
      <template v-slot:item.createdAt="{item}">
        {{ item.createdAt | time }}
      </template>
      <template v-slot:item.email="{item}">
        <a :href="`mailto:${item.email}?subject=Sanadeus`">{{ item.email }}</a>
      </template>
      <template v-slot:item.state="{item}">
        {{ $t(`content.prospects.states.${item.state}`) }}
      </template>
      <template v-slot:item.clientState="{item}">
        {{ item.clientState ? $t(`content.nodes.states.${item.clientState}`) : '' }}
      </template>
      <template v-slot:item.comment="{item}">
        <v-icon v-if="item.comment" :title="$t('content.prospects.comment_present')">mdi-comment</v-icon>
      </template>
      <template v-slot:expanded-item="{ headers,item }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-tabs v-model="item.tab">
              <v-tab :key="`${item._id}_actions`">{{$t('content.prospects.actions')}}</v-tab>
              <v-tab :key="`${item._id}_comment`">{{$t('content.prospects.comment')}}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="item.tab">
              <v-tab-item :key="`${item._id}_actions`">
                <v-list dense>
                  <template v-for="(h,i) in item.stateHistory">
                    <div :key="i">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t(`content.prospects.states.${h.to}`) }}</v-list-item-title>
                          <v-list-item-subtitle>{{ h.at | time }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </div>
                  </template>
                </v-list>
              </v-tab-item>
              <v-tab-item :key="`${item._id}_comment`">
                <v-form @submit.prevent="saveComment(item)" class="py-2">
                <v-textarea v-model="item.comment" filled></v-textarea>
                  <v-btn type="submit">{{$t('content.prospects.save_comment')}}</v-btn>
                  <v-snackbar
                      v-model="saved"
                      absolute
                      bottom
                      color="success"
                      right
                  >
                    {{$t('generic.saved')}}
                  </v-snackbar>
                </v-form>
              </v-tab-item>
            </v-tabs-items>

          </v-container>
        </td>
      </template>
    </v-data-table>
  </AdminCard>
</template>
<script>
import AdminCard from '@/components/generic/AdminCard'
import {ADMIN_COMMENT_PROSPECT} from '@/graphql'

export default {
  name: 'ProspectList',
  components: {AdminCard},
  props: ['prospects'],
  computed: {
    prospectsByState() {
      return this.prospects ? this.prospects.filter((node) => {
        return this.filterState === 'all' || node.state === this.filterState
      }) : []
    }
  },
  data() {
    return {
      filterState: 'all',
      options: {},
      saved: false,
      headers: [
        {'text': this.$t("content.prospects.table.created_at"), value: 'createdAt'},
        {'text': this.$t("content.prospects.table.pid"), value: 'partnerId'},
        {'text': this.$t("content.prospects.table.salutation"), value: 'contact.salutation'},
        {'text': this.$t("content.prospects.table.title"), value: 'contact.title'},
        {'text': this.$t("content.prospects.table.last_name"), value: 'contact.lastName'},
        {'text': this.$t("content.prospects.table.first_name"), value: 'contact.firstName'},
        {'text': this.$t("content.prospects.table.email"), value: 'email'},
        {'text': this.$t("content.prospects.table.phonenumber"), value: 'contact.phonenumber'},
        {'text': this.$t("content.prospects.table.state"), value: 'state'},
        {'text': this.$t("content.prospects.table.client_state"), value: 'clientState'},
        {'text': '', value: 'comment'}
      ],
      searchText: ''
    }
  },
  methods: {
    saveComment (prospect) {
      const vm = this
      this.$apollo.mutate({
        mutation: ADMIN_COMMENT_PROSPECT,
        variables: {
          prospect: {
            _id: prospect._id,
            comment: prospect.comment
          }
        },
        update () {
          vm.saved = true
        }
      })
    }
  },
  created () {
    const options = localStorage.getItem('prospect-list-options')
    if (options) this.options = JSON.parse(options)
  },
  watch: {
    options (newOptions, oldOptions) {
      function isEmpty(obj) {
        for(let prop in obj) {
            return false;
        }
        return true;
      }
      if (!isEmpty(oldOptions)) localStorage.setItem('prospect-list-options', JSON.stringify(newOptions))
    }
  }
}
</script>