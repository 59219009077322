<template>
  <AdminCard no-border v-if="admin_node">
    <v-form v-on:submit.prevent="save()" v-model="valid">
      <v-row>
        <v-col sm="12" lg="9">
          <v-text-field
              :rules="[rules.required]"
              :label="$t('content.nodes.form.name')"
              v-model="admin_node.name"
              dense
          ></v-text-field>
        </v-col>
        <v-col sm="12" lg="3">
          <v-text-field
              :label="$t('content.nodes.form.customerid')"
              v-model="admin_node.customerID"
              readonly
              dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="6">
          <v-text-field
              :label="$t('content.nodes.form.street')"
              v-model="admin_node.street"
              dense
          ></v-text-field>
        </v-col>
        <v-col sm="6" lg="3">
          <v-text-field
              :label="$t('content.nodes.form.postalcode')"
              v-model="admin_node.postalcode"
              dense
          ></v-text-field>
        </v-col>
        <v-col sm="6" lg="3">
          <v-text-field
              :label="$t('content.nodes.form.city')"
              v-model="admin_node.city"
              dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="6">
          <v-text-field
              :label="$t('content.nodes.form.phonenumber')"
              v-model="admin_node.phonenumber"
              dense
          ></v-text-field>
        </v-col>
        <v-col sm="6" lg="3">
          <v-text-field
              :label="$t('content.nodes.form.mobilenumber')"
              v-model="admin_node.mobilenumber"
              type="number"
              dense
          ></v-text-field>
        </v-col>
        <v-col sm="6" lg="3">
          <v-text-field
              :label="$t('content.nodes.form.faxnumber')"
              v-model="admin_node.faxnumber"
              dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn type="sumbit" :disabled="!valid" color="primary white--text">{{$t('forms.save')}}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </AdminCard>

</template>
<script>
import AdminCard from "@/components/generic/AdminCard";
import {ADMIN_NODE, ADMIN_UPDATE_NODE} from "../../../graphql";
import {RULES} from "@/rules";
import {cleanTypename} from "@/utils";

export default {
  name: 'NodeId',
  apollo: {
    admin_node: {
      query: ADMIN_NODE,
      variables() {
        return {
          id: this.$route.params.node_id
        }
      }
    }
  },
  components: {AdminCard},
  computed: {
    rules () {
      return RULES
    }
  },
  props: ['node_id'],
  data () {
    return {
      valid: false
    }
  },
  methods: {
    save () {
      this.$apollo.mutate({
        mutation: ADMIN_UPDATE_NODE,
        variables: {
          node: cleanTypename(this.admin_node)
        },
        update (store, {data: {updateNode}}) {
          let data = store.readQuery({query: ADMIN_NODE, variables: {id: updateNode._id}})
          data.admin_node = updateNode
          store.writeQuery({query: ADMIN_NODE, data})
        }
      })
    }
  }
}
</script>
