<template>
  <v-dialog persistent v-model="value.show" max-width="30vw">
    <v-card tile flat>
      <v-toolbar color="teal darken-3 white--text" dense flat>
        <slot name="title"></slot>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <strong><slot></slot></strong>
          <div v-if="value.success">
            <slot name="success"></slot>
          </div>
        </v-container>
      </v-card-text>
      <v-divider>
      </v-divider>
        <v-toolbar flat dense color="grey lighten-2">
          <v-spacer>
          </v-spacer>
          <v-btn small class="mr-2" @click="close()">{{value.success ? $t('forms.actions.close') : $t('forms.actions.cancel')}}</v-btn>
          <v-btn color="teal darken-4 white--text" v-if="!value.success" small @click="confirm()">{{$t('forms.actions.ok')}}</v-btn>
        </v-toolbar>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'Confirmation',
  props: {
    value: {
      type: Object
    },
    showResult: {type: Boolean, default: false}
  },
  methods: {
    close () {
      this.value.show = false
      this.value.success = false
    },
    confirm() {
      this.value.confirm()
      if (!this.showResult) this.close()
    }
  }
}
</script>