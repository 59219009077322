<template>
  <div>
    <v-app-bar
        app
        clipped-right
        fixed
        class="snds-app-bar"
        flat
    >
      <div>
        <v-img
            class="shrink mr-2 logo"
            container
            src="@/assets/sanadeus.svg"
        />
      </div>
    </v-app-bar>
    <v-navigation-drawer
        app
        permanent
        expand-on-hover
        :mini-variant.sync="navMini"
    >
      <ProfileButton :nav-mini="navMini"/>
      <Navigation/>
      <template v-slot:append>
        <LogoutButton :nav-mini="navMini"/>
      </template>
    </v-navigation-drawer>
    <v-main class="pa-2">
      <router-view></router-view>
    </v-main>
  </div>
</template>
<style>
.snds-app-bar {
  border-bottom: 1px solid rgba(0, 0, 0, .12) !important;
}
</style>
<script>
import ProfileButton from "../components/client/ProfileButton";
import Navigation from "../components/Navigation";
import LogoutButton from "../components/client/LogoutButton";
export default {
  name: 'App',
  components: {
    LogoutButton,
    Navigation,
    ProfileButton
  },
  data: () => ({
    navMini: true
  }),
};
</script>
