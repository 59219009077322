<template>
  <AdminCard :no-border="!showBorder">
    <template slot="title">
      <v-row>
        <v-text-field
            v-model="errorSearch"
            append-icon="mdi-magnify"
            label="Suche..."
            class="px-4"
            single-line
            hide-details
        ></v-text-field>
      </v-row>
    </template>
    <v-data-table :items="errors" item-class="error-message" :items-per-page="20" :footer-props="{itemsPerPageOptions: [20, 50, 100, 200, 500, -1]}" :headers="headers" :search="errorSearch" :loading="loading">
      <template v-slot:item.date="{ item }">
        <span>{{ item.date | time}}</span>
      </template>
    </v-data-table>
  </AdminCard>
</template>
<script>
  import AdminCard from "@/components/generic/AdminCard";
  export default {
    name: 'ErrorList',
    components: {AdminCard},
    props: {
      errors: {type: Array},
      loading: {type: Boolean, default: false},
      showBorder: {type: Boolean, default: false}
    },
    data () {
      return {
        errorSearch: '',
        headers: [
          { text: '_node', value: 'node.name', },
          { text: 'process', value: 'process', },
          { text: 'description', value: 'description', },
          { text: 'originator', value: 'originator', },
          { text: 'date', value: 'date', },
          { text: 'error', value: 'error.name', },
          { text: 'message', value: 'error.message' }
        ]
      }
    }
  }
</script>