<template>
  <div v-if="canView">
    <slot></slot>
  </div>
</template>
<script>
import {LOCAL_CLIENT} from '@/graphql'
export default {
  name: 'RoleContent',
  props: ['role'],
  apollo: {
    localClient: {
      query: LOCAL_CLIENT
    }
  },
  computed: {
    canView () {
      if (!this.role) return true
      try {
        let fields = this.role.split('.')
        let auth = fields.reduce((obj, curr) => {
          return obj[curr] || false
        }, this.localClient.roles || {} )
        return this.localClient.client_type === 'SUPER_ADMIN' || auth === true || typeof(auth) === 'object'
      } catch (err) {
        return false
      }
    }
  }
}
</script>
