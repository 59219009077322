<template>
  <v-card tile outlined>
    <v-toolbar flat tile>
      <v-toolbar-title>
        {{$t('auth.form.login.title')}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-img
          class="shrink mr-2"
          container
          src="@/assets/sanadeus.svg"
      />
    </v-toolbar>
    <v-divider></v-divider>
    <v-form v-on:submit.prevent="login()" v-model="valid">
      <v-card-text class="pt-10">
        <v-text-field
            outlined
            :label="$t('auth.form.login.mail')"
            v-model="credentials.email"
            :rules="[rules.required, rules.email]"
            prepend-inner-icon="mdi-at"
        ></v-text-field>
        <v-text-field
            outlined
            :label="$t('auth.form.login.password')"
            v-model="credentials.password"
            :rules="[rules.required]"
            prepend-inner-icon="mdi-key"
            type="password"
        ></v-text-field>
      </v-card-text>
      <v-alert class="mx-4" v-if="failed" type="error">{{$t("auth.form.login.failed")}}</v-alert>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn block color="primary" :disabled="!valid" type="submit"  class="white--text">{{$t('auth.form.login.submit')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import {ADMIN_LOGIN, SET_CLIENT} from '@/graphql'
import {RULES} from "@/rules"
import {hasLoggedIn} from "@/utils";

export default {
  computed: {
    rules () {
      return RULES
    }
  },
  data () {
    return {
      valid: false,
      failed: false,
      credentials: {
        email: null,
        password: null
      }
    }
  },
  methods: {
    async login () {
      await this.$apollo.mutate({
        mutation: ADMIN_LOGIN,
        variables: {
          email: this.credentials.email,
          password: this.credentials.password
        },
        update: (store, {data: {admin_login}}) => {
          this.$apollo.mutate({
            mutation: SET_CLIENT,
            variables: {client: admin_login},
            update: () => {
              console.log(admin_login)
              const target = this.$route.query.redirect || "/"
              hasLoggedIn(admin_login, target)
            }
          })
        }
      }).catch(() => {
        this.failed = true
      })
    }
  }
}
</script>
