import {SET_CLIENT, LOGGED_IN, LOCAL_CLIENT} from "../graphql/local";
import {APOLLO} from "@/vue-apollo";
import router from '../router'
import {CACHE} from "@/cache";

export async function hasLoggedIn(client, target) {
  await APOLLO.defaultClient.mutate({
    mutation: SET_CLIENT,
    variables: {client: client},
    update: async () => {
      await APOLLO.defaultClient.writeQuery({query: LOGGED_IN, data: {loggedIn: true}})
      if (target) router.push(target || "/")
    }
  })
}

export function checkRole(role) {
  let { localClient } = CACHE.readQuery({query: LOCAL_CLIENT})
  try {
    let fields = role.split('.')
    let auth = fields.reduce((obj, curr) => {
      return obj[curr] || obj
    }, localClient.roles || {})
    return (localClient.client_type === 'SUPER_ADMIN' || auth === true || auth.all)
  } catch {
    return false
  }
}

export function cleanTypename (withTypename) {
  let obj = Object.assign({}, withTypename)
  delete obj.__typename
  return obj
}
