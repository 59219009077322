import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/lib/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light:    {
        primary: '#4caf50',
        secondary: '#607d8b',
        accent: '#009688',
        error: '#f44336',
        warning: '#ff9800',
        info: '#03a9f4',
        success: '#8bc34a'
      }
    },
  },
    lang: {
      locales: { de },
      current: 'de',
    },
});
