<template>
    <div class="pa-2">
        <v-btn @click="logout()" tile block class="black white--text" v-if="!navMini">
            {{$t('auth.form.logout.submit')}}
        </v-btn>
        <v-btn @click="logout()" v-if="navMini" class="black white--text" tile icon :title="$t('auth.form.logout.submit')"><v-icon>mdi-power</v-icon></v-btn>
    </div>
</template>

<script>
import {ADMIN_LOGOUT} from '@/graphql'
  export default {
    name: "LogoutButton",
    props: ['navMini'],
    methods: {
      logout () {
        let vm = this
        this.$apollo.mutate({
          mutation: ADMIN_LOGOUT,
          update () {
            let client = vm.$apollo.getClient()
            client.resetStore()
            vm.$router.push({name: "auth.login"})
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>
