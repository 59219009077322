<template>
  <div class="iframe-wrapper">
    <iframe style="margin:0;" :src="bullUrl" width="100%" :height="setHeight()" id="bullFrame" frameborder="0" ref="bullFrame"></iframe>
  </div>
</template>
<style>
 .iframe-wrapper {
   height: 90vh;
 }
</style>
<script>
    export default {
      computed: {
        bullUrl () {
          return process.env.VUE_APP_BULL_ENDPOINT
        }
      },
      methods: {
        setHeight() {
          let height = `${window.innerHeight - 90}px`
          return height
        }
      }
    }
</script>