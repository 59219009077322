import Vue from 'vue'
import {checkRole} from "@/utils";

const dateoptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
}

const monthoptions = {
  month: 'long',
}

const timeoptions = {
  year: 'numeric', month: '2-digit', day: '2-digit',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: false,
}

Vue.filter('roleEntries', function (entries) {
  if (!entries) return null
  return entries.filter( (entry) => {
    return (!entry.role || checkRole(entry.role)) && !entry.disabled // don't show disabled ones
  })
})

Vue.filter('date', function (value) {
  return new Intl.DateTimeFormat('de-DE', dateoptions).format(value)
})

Vue.filter('dateFromStamp', function (value) {
  const date = new Date(value)
  return new Intl.DateTimeFormat('de-DE', dateoptions).format(date)
})

Vue.filter('month', function (value) {
  return new Intl.DateTimeFormat('de-DE', monthoptions).format(value)
})

Vue.filter('time', function (value) {
  return new Intl.DateTimeFormat('de-DE', timeoptions).format(value)
})
