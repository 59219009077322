<template>
    <AdminPage>
      <template slot="header">Dashboard</template>
      <h3>Hallo {{localClient.firstName}} {{localClient.lastName}}!</h3>
    </AdminPage>
</template>
<script>
  import {LOCAL_CLIENT} from "../../graphql/local";
  import AdminPage from '@/components/generic/AdminPage';

  export default {
    components: {AdminPage},
    title: "Dashboard",
    apollo: {
      localClient: {
        query: LOCAL_CLIENT
      }
    }
  }
</script>
