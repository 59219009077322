<template>
  <LoginForm></LoginForm>
</template>
<script>
import LoginForm from "@/components/LoginForm";
export default {
  components: {
    LoginForm
  }
}
</script>
