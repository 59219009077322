<template>
  <JsonContent :json-item="admin_node_raw"></JsonContent>
</template>
<script>
import {ADMIN_NODE_RAW} from "../../../graphql/queries";
import JsonContent from "@/components/generic/JsonContent";

export default {
  components: {JsonContent},
  apollo: {
    admin_node_raw: {
      query: ADMIN_NODE_RAW,
      fetchPolicy: 'network-only', // falls was geändert wird - nicht reactive
      variables() {
        return {
          id: this.$route.params.node_id
        }
      }
    }
  }
}
</script>