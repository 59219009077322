<template>
  <AdminCard>
    <template slot="title">
      {{$t('forms.node.new')}}
    </template>
    {{parentNode}}
  </AdminCard>
</template>
<script>
import AdminCard from "@/components/generic/AdminCard";
export default {
  name: 'NewNode',
  props: ['parentNode'],
  components: {AdminCard}
}
</script>
