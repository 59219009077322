import { CACHE } from '@/cache'
import { ApolloClient } from 'apollo-client'
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import LOCAL_SCHEMA from './graphql/local/local.schema.graphql'
import LOCAL_RESOLVERS from './graphql/local/resolvers'
// Install the vue plugin
Vue.use(VueApollo)

let _apollo

// Http endpoint
const httpLink = createUploadLink({
  uri: config.VUE_APP_GRAPHQL_ENDPOINT,
  headers: { 'Apollo-Require-Preflight': 'true' },
  credentials: 'include',
})
const wsLink = new WebSocketLink({
  uri: config.VUE_APP_GRAPHQL_WS,
  options: {
    reconnect: true,
    credentials: 'include',
  },
})
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink,
)

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  //httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: config.VUE_APP_GRAPHQL_WS || 'ws://localhost:3001/subscriptions',
  // LocalStorage token
  //tokenName: "snds_token",
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,
  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  httpLinkOptions: {
    credentials: 'include',
  },
  connectToDevTools: true,
  link: link,
  // Override default cache
  // TODO how to use InMemoryCache with custom TypePolicies
  cache: CACHE,

  // Override the way the Authorization header is set
  //getAuth: (tokenName) => {}

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  resolvers: LOCAL_RESOLVERS,
  typeDefs: LOCAL_SCHEMA,
}

// Call this in the Vue app file
function createProvider(options = {}) {
  const apolloClient = new ApolloClient({
    ...defaultOptions,
    ...options,
  })
  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {},
    // TODO error handling
    /*    // eslint-disable-next-line no-unused-vars
        errorHandler (err) {
          errorHandler(err)
        }*/
  })
}

if (!_apollo) {
  _apollo = createProvider()
}

export const APOLLO = _apollo

/*
// Manually call this when user log in
export async function onLogin (apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
*/
