<template>
    <v-list>
        <v-list-item link>
            <v-list-item-content>
                <v-list-item-title class="text-center">
                    {{buttonName}}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>
<script>
    import {LOCAL_CLIENT} from "@/graphql"
    export default {
      name: "ProfileButton",
      props: ['navMini'],
      apollo: {
        localClient: {
          query: LOCAL_CLIENT
        }
      },
      computed: {
        buttonName () {
          let name = `${this.localClient.firstName} ${this.localClient.lastName}`
          if (this.navMini) {
            let names = name.split(" ")
            name = `${names[0].charAt(0).toUpperCase()}${names[names.length-1].charAt(0).toUpperCase()}`
          }
          return name
        }
      }
    }
</script>
<style scoped>

</style>