<template>
    <v-card tile outlined :class="{'no-border': this.noBorder}">
      <v-card-title v-if="$slots.title">
        <slot name="title"></slot>
      </v-card-title>
      <v-card-text>
          <slot></slot>
      </v-card-text>
      <v-divider v-if="$slots.actions"></v-divider>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
</template>
<script>
export default {
  name: 'AdminCard',
  props: {
    noBorder: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.no-border {
  border: 0 !important;
}
</style>
