<template>
  <div>
    <div class="pa-2">
      <v-btn small @click="passwordDialog.show = true">{{$t("forms.new_password.button")}}</v-btn>
    </div>
    <Confirmation v-model="passwordDialog" show-result>
      <template slot="title">{{$t("forms.new_password.title")}}</template>
      <p>{{$t("forms.new_password.confirmation_text")}}</p>
      <template slot="success">
        <v-alert dense type="success" :value="true">
          {{$t("forms.new_password.success_text")}} {{ password }}
        </v-alert>
      </template>
    </Confirmation>

  </div>
</template>
<script>
import ADMIN_RESET_CLIENT_PASSWORD from '@/graphql/mutations/admin_reset_client_password.graphql'
import Confirmation from '@/components/generic/Confirmation';

export default {
  name: 'NewPassword',
  components: {Confirmation},
  props: ['id'],
  data() {
    return {
      password: '',
      passwordDialog: {
        show: false,
        success: false,
        confirm: () => {
          this.setNewPassword(this.id)
        }
      }
    }
  },
  methods: {
    setNewPassword(id) {
      this.$apollo.mutate({
        mutation: ADMIN_RESET_CLIENT_PASSWORD,
        variables: {
          id: id
        },
        update: (store, {data: {admin_reset_client_password}}) => {
          this.password = admin_reset_client_password
          this.passwordDialog.success = true
          console.log(admin_reset_client_password)
        }
      })
    }
  }
}
</script>