<template>
    <AdminCard>
      <template slot="title">
        <h2>Baum</h2>
      </template>
      <node-tree v-if="admin_node_tree" :level="1" :node="admin_node_tree.tree"></node-tree>
    </AdminCard>
</template>
<script>
import NodeTree from "@/components/admin/nodes/NodeTree";
import AdminCard from "@/components/generic/AdminCard";
import {ADMIN_NODE_TREE} from "../../../graphql/queries";
import {LOCAL_CLIENT} from "../../../graphql/local";
export default {
  components: {AdminCard, NodeTree},
  apollo: {
    localClient: {
      query: LOCAL_CLIENT
    },
    admin_node_tree: {
      query: ADMIN_NODE_TREE,
      variables() {
        return {
          id: this.localClient.node._id
        }
      }
    }
  }
}
</script>
