var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('content.company_notifications.dialog.add.title'))+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.template.items,"dense":"","label":"Vorlage","outlined":"","return-object":""},model:{value:(_vm.template.selected),callback:function ($$v) {_vm.$set(_vm.template, "selected", $$v)},expression:"template.selected"}})],1)],1),_c('v-form',{ref:"formRef",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('content.company_notifications.form.labels.title'),"rules":[_vm.rules.required],"dense":"","outlined":""},model:{value:(_vm.form.companyNotification.title),callback:function ($$v) {_vm.$set(_vm.form.companyNotification, "title", $$v)},expression:"form.companyNotification.title"}})],1)],1),_c('v-row',[_c('v-col',[_c('label',[_vm._v(" "+_vm._s(_vm.$t('content.company_notifications.form.labels.content'))+" ")]),_c('vue-ckeditor',{attrs:{"config":_vm.editorConfig},model:{value:(_vm.form.companyNotification.content),callback:function ($$v) {_vm.$set(_vm.form.companyNotification, "content", $$v)},expression:"form.companyNotification.content"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-datetime-picker',{attrs:{"date-picker-props":{
                               outlined: true,
                               dense: true,
                               color: 'primary',
                               locale: 'de',
                               firstDayOfWeek: 1
                             },"label":_vm.$t('content.company_notifications.form.labels.publishAt'),"text-field-props":{
                               outlined: true,
                               dense: true,
                               color: 'primary'
                             },"time-picker-props":{
                               format: '24hr',
                               outlined: true,
                               dense: true,
                               color: 'primary'
                             },"clearText":"Abbrechen","okText":"Übernehmen"},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-calendar-blank")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-clock-outline")])]},proxy:true}]),model:{value:(_vm.form.companyNotification.publishAt),callback:function ($$v) {_vm.$set(_vm.form.companyNotification, "publishAt", $$v)},expression:"form.companyNotification.publishAt"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":_vm.$t('content.company_notifications.form.labels.showReloadAction'),"dense":"","outlined":""},model:{value:(_vm.form.companyNotification.showReloadAction),callback:function ($$v) {_vm.$set(_vm.form.companyNotification, "showReloadAction", $$v)},expression:"form.companyNotification.showReloadAction"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('content.company_notifications.actions.save'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }