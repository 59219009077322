<template>
  <AdminCard v-if="localClient">
    <template slot="title">
      <v-flex>
      <v-row>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-dialog v-if="false" v-model="dialog" max-width="80vw">
          <template v-slot:activator="{on, attrs}">
            <v-btn color="primary white--text"
                   v-bind="attrs"
                   v-on="on">
              {{$t('forms.node.new')}}
            </v-btn>
          </template>
          <NewNode :parent-node="localClient.node._id"></NewNode>
        </v-dialog>
      </v-toolbar>
      </v-row>
      <v-row>
      <v-text-field
          v-model="nodeName"
          append-icon="mdi-magnify"
          :label="$t('generic.search_hint')"
          class="px-4"
          single-line
          hide-details
      ></v-text-field>
      </v-row>
        <v-row>
          <v-radio-group
              v-model="filterState"
              row
              class="px-4"
          >
            <v-radio
                :label="$t('content.nodes.states.all')"
                value="all"
            ></v-radio>
            <v-radio
                :label="$t('content.nodes.states.active')"
                value="active"
            ></v-radio>
            <v-radio
                :label="$t('content.nodes.states.trial')"
                value="trial"
            ></v-radio>
            <v-radio
                :label="$t('content.nodes.states.demo')"
                value="demo"
            ></v-radio>
            <v-radio
                :label="$t('content.nodes.states.cancelled')"
                value="cancelled"
            ></v-radio>
          </v-radio-group>
        </v-row>
      </v-flex>
    </template>
    <v-data-table :items="nodesByState" :headers="headers" :search="nodeName"
                  :loading="$apollo.queries.admin_nodes.loading" @click:row="openDetails"  :options.sync="options" :footer-props="{itemsPerPageOptions: [10, 20, 50, 100, -1]}">
      <template v-slot:item.state="{ item }">
        {{$t(`content.nodes.table.${item.state}`)}}
      </template>
    </v-data-table>
  </AdminCard>
</template>
<script>
import {ADMIN_NODES, ADD_NODE_TAB, LOCAL_CLIENT} from '@/graphql'
import AdminCard from "@/components/generic/AdminCard";
import NewNode from "@/components/admin/nodes/NewNode";

export default {
  name: 'NodeList',
  components: {NewNode, AdminCard},
  apollo: {
    localClient: {
      query: LOCAL_CLIENT
    },
    admin_nodes: {
      query: ADMIN_NODES
    }
  },
  computed: {
    nodesByState () {

      return this.admin_nodes ? this.admin_nodes.filter((node) => {
        return node.type === 'DOCTOR'
      }).filter((node) => {
        return this.filterState === 'all' || node.state === this.filterState
      }) : []
    }
  },
  data() {
    return {
      dialog: false,
      nodeName: '',
      filterState: 'all',
      options: {},
      headers: [
        {
          text: this.$t("content.nodes.table.customer_number"),
          value: "customerID",
          width: "15%",
        },
        {
          text: this.$t("content.nodes.table.name"),
          value: "name",

        },
        {
          text: this.$t("content.nodes.table.type"),
          value: "type",
        },
        {
          text: this.$t("content.nodes.table.state"),
          value: "state",
          width: "10%",
          filterable: true
        }
      ]
    }
  },
  methods: {
    openDetails(data) {
      let vm = this
      this.$apollo.mutate({
        mutation: ADD_NODE_TAB,
        variables: {
          tab: {
            _id: data._id,
            name: data.name,
            link: {name: 'admin.nodes.list.node_id', params: {node_id: data._id}},
            closable: true
          }
        },
        update() {
          vm.$router.push({name: 'admin.nodes.list.node_id', params: {node_id: data._id}})
        }
      })
    }
  },
  beforeDestroy() {
    localStorage.setItem('node-list-options', JSON.stringify(this.options))
  },
  created () {
    const options = localStorage.getItem('node-list-options')
    if (options) this.options = JSON.parse(options)
  }
}
</script>
<style>
th.bold {
  font-weight: bold;
}
</style>
