<template>
  <div class="node-tree">
      <div class="leaf-wrapper">
        <div class="expander" v-ripple @click="open = !open">
          <v-icon v-if="!open && node.children.length">mdi-plus</v-icon>
          <v-icon v-if="open && node.children.length">mdi-minus</v-icon>
        </div>
        <div href="#" v-ripple class="leaf pa-2" @click="openDetails(node)">{{ node.name }}
        </div>
        <v-chip v-if="childCounter" small label>{{childCounter}}</v-chip>
      </div>
      <div v-show="open">
        <node-tree :style="css" :level="parseInt(level) + 1" v-for="node in node.children" :key="node._id" :node="node"></node-tree>
      </div>
  </div>
</template>
<style>
.node-tree {
  display: flex;
  flex:1 1 auto;
  flex-wrap: wrap;
  flex-direction: column;
}
.leaf-wrapper {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  margin-left: var(--leftPad);
}

/*  .leaf-wrapper:last-child {
    border-bottom: 1px solid darkolivegreen;
  }*/
.expander {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;

}
</style>
<script>
//import EventBus from "@/event-bus";
import {ADD_NODE_TAB} from "../../../graphql/local";

export default {
  name: 'node-tree',
  props: ['node', 'level'],
  computed: {
    css () {
      return {
        "--leftPad": `${this.level * 35}px`
      }
    },
    childCounter () {
      return this.node.children.filter((node) => {
        return node.type !== 'STRUCTURE'
      }).length || null
    }
  },
  data () {
    return {
      open: false
    }
  },
  mounted () {
    const nodeTree = JSON.parse(localStorage.getItem('node-tree')) || {}
    if (nodeTree[this.node._id]) {
      this.open = nodeTree[this.node._id].open
    }
  },
  beforeDestroy() {
    let nodeTree = JSON.parse(localStorage.getItem('node-tree')) || {}
    nodeTree[this.node._id] = {open: this.open}
    localStorage.setItem('node-tree', JSON.stringify(nodeTree))
  },
  methods: {
    openDetails(node) {
      let vm = this
      if (node.type !== 'STRUCTURE') {
        this.$apollo.mutate({
          mutation: ADD_NODE_TAB,
          variables: {
            tab: {
              _id: node._id,
              name: node.name,
              link: {name: 'admin.nodes.list.node_id', params: {node_id: node._id}},
              closable: true
            }
          },
          update() {
            vm.$router.push({name: 'admin.nodes.list.node_id', params: {node_id: node._id}})
          }
        })
      }
    }
  }
}
</script>
