import {LOCAL_CLIENT, LOGGED_IN, NODE_TABS} from '@/graphql'

export default {
  Mutation: {
    addNodeTab: (root, {tab}, {cache}) => {
      try {
        let data = cache.readQuery({query: NODE_TABS})
        let open = data.nodeTabs.find((node) => {
          return node._id === tab._id
        })
        if (open) {
          open.link = tab.link
        }
        if (!open) {
          data.nodeTabs.push({__typename: 'TabNavEntry', ...tab})
        }
        cache.writeQuery({query: NODE_TABS, data})
      } catch (err) {
        cache.writeQuery({query: NODE_TABS, data: {nodeTabs: [{__typename: 'TabNavEntry', ...tab}]}})
      }
    },
    closeTab: (root, {tab, tabType}, {cache}) => {
      let typename, query
      switch (tabType.toLowerCase()) {
        case "node":
          typename = 'nodeTabs'
          query = NODE_TABS
          break;
      }
      let data = cache.readQuery({query: query})
      let entry = data[typename].find((t) => {
        return t._id === tab._id
      })
      if (entry) {
        data[typename].splice(data[typename].indexOf(entry), 1)
        cache.writeQuery({query: query, data})
      }
    },
    setClient: (root, {client}, {cache}) => {
      cache.writeQuery({query: LOCAL_CLIENT, data: {localClient: client}})
      cache.writeQuery({query: LOGGED_IN, data: {loggedIn: true}})
      return true
    }
  }
}
